/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { Observable, iif, of, throwError } from 'rxjs';
import { AjaxRequest, ajax } from 'rxjs/ajax';
import { concat, delay, filter, map, retryWhen, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { environment } from '../../../shared/environment';
import settings$ from '../settings';

type Get = <T>(
  url: string,
  options?: Partial<AjaxRequest>,
  retry?: boolean,
  appendCustomApiParams?: boolean,
) => Observable<T>;
type Post = <T, BT>(url: string, body: BT, options?: Partial<AjaxRequest>, retry?: boolean) => Observable<T>;
type Patch = <T, BT>(url: string, body: BT, options?: Partial<AjaxRequest>, retry?: boolean) => Observable<T>;
type Put = <T, BT>(url: string, body: BT, options?: Partial<AjaxRequest>, retry?: boolean) => Observable<T>;
type Remove = <T>(url: string, options?: Partial<AjaxRequest>, retry?: boolean) => Observable<T>;

export type HTTP = {
  get: Get;
  post: Post;
  patch: Patch;
  put: Put;
  remove: Remove;
  getNew: Get;
  postNew: Post;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkForOrionTipAuthError = (e: any) => e.status === 403;

const makeRequest = (data: Partial<AjaxRequest>, retry: boolean, appendCustomApiParams = false) => {
  return settings$.pipe(
    filter(settings => !!settings),
    // @ts-ignore
    switchMap(({ provider }) => {
      const externalApiUrl = environment.apiUrls[provider];
      const customApiParams = environment.apiParams[provider];
      return ajax({
        ...data,
        url: `${externalApiUrl}/${data.url}${appendCustomApiParams && customApiParams ? `&${customApiParams}` : ''}`,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }),
    retryWhen(errors =>
      errors.pipe(
        withLatestFrom(settings$),
        // @ts-ignore
        switchMap(([e]) =>
          checkForOrionTipAuthError(e)
            ? throwError(e).pipe(
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                tap(() => window.AleaControlShell.reconnectGame('OrionTip', 'SessionExpired')),
              )
            : iif(() => retry, of(e), throwError(e)),
        ),
        delay(1000),
        take(3),
        concat(throwError(errors)),
      ),
    ),
    map(res => res.response),
  );
};

const makeRequestNew = (data: Partial<AjaxRequest>, retry: boolean, appendCustomApiParams = false) => {
  return settings$.pipe(
    filter(settings => !!settings),
    // @ts-ignore
    switchMap(({ provider }) => {
      const externalApiUrl = environment.eventsApiUrls[provider];
      const customApiParams = environment.apiParams[provider];
      return ajax({
        ...data,
        url: `${externalApiUrl}/${data.url}${appendCustomApiParams && customApiParams ? `&${customApiParams}` : ''}`,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }),
    retryWhen(errors =>
      errors.pipe(
        withLatestFrom(settings$),
        // @ts-ignore
        switchMap(([e]) =>
          checkForOrionTipAuthError(e)
            ? throwError(e).pipe(
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                tap(() => window.AleaControlShell.reconnectGame('OrionTip', 'SessionExpired')),
              )
            : iif(() => retry, of(e), throwError(e)),
        ),
        delay(1000),
        take(3),
        concat(throwError(errors)),
      ),
    ),
    map(res => res.response),
  );
};

const get: Get = (url, options = {}, retry = true, appendCustomApiParams = true) =>
  makeRequest({ method: 'GET', url, ...options }, retry, appendCustomApiParams);

const post: Post = (url, body, options = {}, retry = true) =>
  makeRequest({ method: 'POST', url, body, ...options }, retry);

const patch: Patch = (url, body, options = {}, retry = true) =>
  makeRequest({ method: 'PATCH', url, body, ...options }, retry);

const put: Put = (url, body, options = {}, retry = true) =>
  makeRequest({ method: 'PUT', url, body, ...options }, retry);

const remove: Remove = (url, options = {}, retry = true) => makeRequest({ method: 'DELETE', url, ...options }, retry);

const getNew: Get = (url, options = {}, retry = true, appendCustomApiParams = true) =>
  makeRequestNew({ method: 'GET', url, ...options }, retry, appendCustomApiParams);

// const postNew: Post = (url, body, options = {}, retry = true) =>
//   makeRequestNew({ method: 'POST', url, body, ...options }, retry);

const HTTP = { get, post, patch, put, remove, getNew };

export default HTTP;
